export const babybed = 8;
export const animal = 9;
export const dej = 9.5;
export const taxe = 0.99;

export const closeDate = []
export const BS = ["09/03-03/04", "04/05-06/05", "13/05-16/05", "21/05-30/05", "01/10-16/10", "03/11-18/12", "06/01-08/02"];
export const MS = ["07/02-08/03", "04/04-03/05", "07/06-30/06","17/10-02/11", "19/12-05/01"];
export const HS = ["07/05-28/05", "01/07-30/08", "01/07-30/09"];

export const services = [
    {
        id: 1,
        title: "Petit déjeuner",
        price: dej,
    },
    {
        id: 2,
        title: "Lit bébé",
        price: babybed,
    },
    {
        id: 3,
        title: "Animal",
        price: animal,
    },
    { 
        id: 4,
        title: "Micro-ondes à disposition",
        price: 0,
    }
];

export const translations = {
    fr: {
      bathroom: "Salle de bain",
      douche: "Douche",
      bain: "Baignoire",
      beds: "Lits",
      person: "Personne(s)",
      withTerrace: "Avec terrasse",
      lowSeason: "Basse saison",
      midSeason: "Moyenne saison",
      highSeason: "Haute saison",
      capacity: "Capacité",
      singleBed: "lit(s) simple",
      doubleBed: "lit(s) double"
    },
    en: {
      bathroom: "Bathroom",
      douche: "Shower",
      bain: "Bath",
      beds: "Beds",
      person: "Person(s)",
      withTerrace: "With terrace",
      lowSeason: "Low season",
      midSeason: "Mid season",
      highSeason: "High season",
      capacity: "Capacity",
      singleBed: "single bed(s)",
      doubleBed: "double bed(s)"
    }
  };
  